
//const
export const url_login = '/auth/login';
export const url_logout = '/auth/logout';
export const url_export = '/business/file/export';
export const url_upload = '/business/file/upload';
export const url_websocket = 'wss://www.forsrc.com/business/websocket';
// export const url_websocket = 'ws://localhost:6101/business/websocket';
// export const url_websocket = 'ws://localhost:6101/business/websocket/netty';

//
export const chart_item_count = 10;

// 上传文件最大字节数，M
export const upload_file_size_max = 50;

export const code = {
  authentication_from: 1000,
  authentication_to: 1020
}

export const userid = 'userId';
export const token = 'Authorization';
export const authorization = 'Authorization';
export const token_day = 7;  //token 过期时间，7 天

//configure
export const enable_debug = true;  //是否为调试, true 为本机调试, false 为发布。
